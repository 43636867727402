import React from 'react'
import Page from '../components/Page'
import SEO from '../components/seo'
import NotFound404 from '../components/NotFound404'

const NotFoundPage = () => (
  <Page>
    <SEO title="404: Not found" />
    <NotFound404 />
  </Page>
)

export default NotFoundPage
