// @flow
import * as React from 'react'
import {Link} from 'gatsby'
import * as styles from './NotFound404.module.css'

import OpentronsLogo from './assets/404_150x150.gif'

export default function NotFound404() {
  return (
    <>
      <div className={styles.background_container}>
        <div className={styles.container}>
          <img className={styles.logo} src={OpentronsLogo} />
          <h2 className={styles.h2}>404—Page Not Found</h2>
          <h5 className={styles.h5}>
            Sorry, we can’t find the page you’re looking for. Go to the{' '}
            <Link className={styles.homepage_link} to="/">
              Opentrons Homepage
            </Link>{' '}
            instead.
          </h5>
        </div>
      </div>
    </>
  )
}
